.container {
  font-size: 7rem;
  letter-spacing: 1rem;
  text-align: center;
  padding: 7rem 0;
  background-image: url("../../assets/images/main-background.png");
  background-position: center bottom;
  background-size: contain;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 610px) {
  .container {
    padding: 1rem 0 7rem;
  }
}

.container img {
  height: 150px;
}

@media only screen and (max-width: 610px) {
  .container img {
    height: 110px;
  }
}