/* Provide sufficient contrast against white background */

html {
  --bg-color: #120d06;
  --pr-fg-color: #e8b52e;
  --ar-fg-color: #e4b936;
  --sr-fg-color: #ffe9a0;
  --sd-fg-color: white;
}

body {
  background-color: var(--bg-color);
}
