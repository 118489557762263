.container {}

.header {
  color: var(--sd-fg-color);
  font-size: 2rem;
  font-weight: 500;
  text-align: center;
}

.notice {
  color: var(--pr-fg-color);
  font-size: 0.9rem;
  font-weight: 200;
  text-transform: uppercase;
  text-align: center;
}

.nominations-container {
  display: flex;
  flex-direction: column;
  padding: 30px 0px 80px 0px;
}

.nominations-container .nominations-row {
  text-align: center;
  margin-top: -10px;
  margin-bottom: -10px;
  padding: 0;
}

@media only screen and (max-width: 768px) {
  .nominations-container .nominations-row {
    margin-top: 0;
    margin-bottom: -5px;
  }
}

@media only screen and (max-width: 768px) {
  .nominations-container .nominations-row {
    margin-bottom: 0;
  }
}

.nominations-row:last-child {
  min-height: 200px;
}

.nominations-container  .winners-row {
  text-align: center;
  margin-bottom: 50px;
  padding: 0;
}

@media only screen and (max-width: 768px) {
  .nominations-container  .winners-row {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 610px) {
  .nominations-container  .winners-row {
    margin-bottom: 0px;
  }
}

.nominations-container .nomination-container {
  display: inline-table;
  word-spacing: 100px;
  text-align: center;
  margin: 0 60px;
  color: black;
  background-color: var(--pr-fg-color);
}

@media only screen and (max-width: 768px) {
  .nominations-container .nomination-container {
    margin: 0 42px;
  }
}

.nominations-container .winner-award-container {
  display: inline-table;
  text-align: center;
  margin: 0 60px;
  color: black;
  background-color: var(--ar-fg-color);
}

@media only screen and (max-width: 768px) {
  .nominations-container .winner-award-container {
    margin: 0 40px;
  }
}

@media only screen and (max-width: 610px) {
  .nominations-container .winner-award-container {
    margin: 0 35px;
  }
}

.nominations-container .selected-nomination-container {
  font-size: 5px;
  display: inline-table;
  text-align: center;
  margin: 0 60px;
  color: black;
  background-color: var(--sr-fg-color);
}

.nominations-container .nomination-container::before {
  border-right-color: var(--pr-fg-color);
}

.nominations-container .nomination-container::after {
  border-left-color: var(--pr-fg-color);
}

@media only screen and (max-width: 768px) {
  .nominations-container .minimized.selected-nomination-container {
    margin: 0 40px;
    background-color: var(--pr-fg-color);
  }

  .nominations-container .minimized.selected-nomination-container::before {
    border-right-color: var(--pr-fg-color);
  }

  .nominations-container .minimized.selected-nomination-container::after {
    border-left-color: var(--pr-fg-color);
  }
}

@media only screen and (max-width: 610px) {
  .nominations-container .minimized.selected-nomination-container {
    margin: 0 35px;
  }
}

.nominations-container .winner-award-container::before {
  border-right-color: var(--ar-fg-color);
}

.nominations-container .winner-award-container::after {
  border-left-color: var(--ar-fg-color);
}

.nominations-container .selected-nomination-container::before {
  border-right-color: var(--sr-fg-color);
}

.nominations-container .selected-nomination-container::after {
  border-left-color: var(--sr-fg-color);
}
