.container {}

.header {
  color: var(--sd-fg-color);
  font-size: 2rem;
  font-weight: 500;
  text-align: center;
}

.digits-container {
  padding: 20px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.digits-container .digits {
  padding: 0 20px;
  font-size: 4rem;
  font-weight: 600;
  text-align: center;
}

.digits-container .digits label {
  display: block;
  font-size: .8rem;
  font-weight: normal;
  text-transform: uppercase;
  color: var(--sd-fg-color);
}

.digits-container .symbols {
  font-size: 3rem;
  font-weight: 600;
  padding: 10px 0;
  align-self: baseline;
  color: var(--sd-fg-color);
}
