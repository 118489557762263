:global(.ant-form-item) {
  margin-bottom: 5px;
}

.container :global(.ant-modal-content) {
  border-radius: 10px;
}

.container :global(.ant-progress-text) {
  color: var(--bg-color);
}

.content {
  padding: 20px 10px 0 10px;
}

.title {
  font-weight: bold;
  font-size: 16px;
}

.row {
  display: flex;
  flex-direction: row;
  margin: 5px 0 5px 0;
}

.textarea {
  margin-bottom: 10px;
}

.input {
  flex-basis: 0;
  flex-grow: 1;

  border: #3f3f3f solid 1px;
  border-radius: 10px;
  flex-direction: column;
  display: block;
}

.input :global(.ant-form-item-explain) {
  display: none;
}

.input :global(.ant-form-item-label) {
  text-align: left;
  line-height: initial;
}

.input :global(.ant-form-item-label) > label {
  height: auto;
  font-size: 12px;
  padding-left: 11px;
  color: #808080;
}

.input :global(.ant-form-item-label) > label::after {
  content: none;
}

.input :global(.ant-form-item-label) .required-label {
  font-size: 10px;
}

@media only screen and (max-width: 610px) {
  .input :global(.ant-form-item-label) .required-label {
    display: block;
  }
}

.input :global(.ant-form-item-control-input) {
  min-height: auto;
}

.input.no-label input {
  padding: 5px 11px;
}

.input-border {
  border: #3f3f3f solid 1px;
  border-radius: 10px;
  border: none !important;
  box-shadow: none !important;
  padding: 0px 11px;
}

.input-border:hover {
  border-color: var(--ar-fg-color);;
}

.input-border:focus {
  border: var(--pr-fg-color) solid 1px;
  /* box-shadow: 0 0 0 2px rgb(233 181 48 / 20%); */
}

.input-border::after {
  font-size: 0.6rem;
  margin-top: 1px;
}

.input-border textarea {
  border: none !important;
  border-radius: 10px !important;
  box-shadow: none !important;
  padding: 0;
}

.right {
  margin-left: 5px;
}

.row-title {
  color: #808080;
  font-size: 11px;
  margin-bottom: 5px;
}

.row-label {
  color: rgba(0,0,0, 0.85);
  font-size: 16px;
  font-weight: bold;
}

.input-button {
  color: #808080;
  border-radius: 10px;
  border: #3f3f3f solid 1px;
}

.input-button:hover {
  border-color: var(--ar-fg-color);
  color: var(--ar-fg-color);
}

.input-button:focus {
  color: var(--pr-fg-color);
  border: var(--pr-fg-color) solid 1px;
  box-shadow: 0 0 0 2px rgb(233 181 48 / 20%);
}

.add-button {
  display: inline-block;
}

.submit-button {
  color: #e4e4e4;
  background-color: #000000;
  border-radius: 10px;
  border: #3f3f3f solid 1px;
}

.submit-button:hover {
  color: var(--ar-fg-color);
  border: var(--pr-fg-color) solid 1px;
  background-color: #000000;
}

.submit-button:focus {
  color: var(--pr-fg-color);
  border: var(--pr-fg-color) solid 1px;
  box-shadow: 0 0 0 2px rgb(233 181 48 / 20%);
}

.mb20 {
  margin-bottom: 20px;
}

.modal-footer {
  padding: 0 10px 0 10px;
  display: flex;
  flex-direction: row;
}

.modal-footer-button {
  flex-basis: 0;
  flex-grow: 1;
}

.form-logo {
  text-align: center;
}

.success-thx {
  text-align: center;
  font-size: 34px;
  font-weight: 500;
  letter-spacing: 1px;
}

.success-text {
  text-align: center;
  color: #555554;
  font-size: 16px;
  padding-top: 5px;
}

.form-logo img {
  width: 55%;
}

.half {
  flex-basis: 0;
  flex-grow: 0.5;
  margin-right: 10px;
}

input:-internal-autofill-selected,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active
{
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  background-color: white !important;
}
