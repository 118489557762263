.wrapper {
  display: inline-table;
  position: relative;
}

@media only screen and (max-width: 610px) {
  .wrapper {
    margin: 0 15px;
  }
}

.container {
  width: 82px;
  height: 130px;
  background: transparent;
  position: relative;
}

.container::before {
  content: "";
  position: absolute;
  top: 0;
  left: -32px;
  width: 0;
  height: 0;
  border-right: 32px solid transparent;
  border-bottom: 65px solid transparent;
  border-top: 65px solid transparent;
}

.container::after {
  content: "";
  position: absolute;
  top: 0;
  right: -32px;
  width: 0;
  height: 0;
  border-left: 32px solid transparent;
  border-bottom: 65px solid transparent;
  border-top: 65px solid transparent;
}

@media only screen and (max-width: 768px) {
  .container {
    width: 82px;
    height: 130px;
    border-radius: 2px;
  }

  .container::before {
    left: -32px;
    border-right: 33px solid transparent;
    border-bottom: 65px solid transparent;
    border-top: 65px solid transparent;
  }

  .container::after {
    right: -32px;
    border-left: 33px solid transparent;
    border-bottom: 65px solid transparent;
    border-top: 65px solid transparent;
  }
}

@media only screen and (max-width: 610px) {
  .container {
    width: 140px;
    height: 210px;
    border-radius: 2px;
  }

  .container::before {
    left: -46px;
    border-right: 47px solid transparent;
    border-bottom: 105px solid transparent;
    border-top: 105px solid transparent;
  }

  .container::after {
    right: -46px;
    border-left: 47px solid transparent;
    border-bottom: 105px solid transparent;
    border-top: 105px solid transparent;
  }
}

.winner-container {
  width: 107px;
  height: 170px;
  background: transparent;
  position: relative;
}

.winner-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: -40px;
  width: 0;
  height: 0;
  border-right: 40px solid transparent;
  border-bottom: 85px solid transparent;
  border-top: 85px solid transparent;
}

.winner-container::after {
  content: "";
  position: absolute;
  top: 0;
  right: -40px;
  width: 0;
  height: 0;
  border-left: 40px solid transparent;
  border-bottom: 85px solid transparent;
  border-top: 85px solid transparent;
}

@media only screen and (max-width: 768px) {
  .winner-container {
    width: 82px;
    height: 130px;
    border-radius: 2px;
  }

  .winner-container::before {
    left: -32px;
    border-right: 33px solid transparent;
    border-bottom: 65px solid transparent;
    border-top: 65px solid transparent;
  }

  .winner-container::after {
    right: -32px;
    border-left: 33px solid transparent;
    border-bottom: 65px solid transparent;
    border-top: 65px solid transparent;
  }
}

@media only screen and (max-width: 610px) {
  .winner-container {
    width: 140px;
    height: 210px;
    border-radius: 2px;
  }

  .winner-container::before {
    left: -46px;
    border-right: 47px solid transparent;
    border-bottom: 105px solid transparent;
    border-top: 105px solid transparent;
  }

  .winner-container::after {
    right: -46px;
    border-left: 47px solid transparent;
    border-bottom: 105px solid transparent;
    border-top: 105px solid transparent;
  }
}

.container.invisible {
  visibility: hidden;
}

.winner-container.invisible {
  visibility: hidden;
}

@media only screen and (max-width: 768px) {
  .container.invisible {
    visibility: visible;
  }

  .winner-container.invisible {
    visibility: visible;
  }
}

.selectedContainer {
  width: 130px;
  height: 160px;
  background: var(--sr-fg-color);;
  position: absolute;
  transform: scale(1);
  z-index: 10;
  font-size: 10px;
  visibility: hidden;
  transition: transform 0.3s ease-in-out;
}

.selectedContainer::before {
  content: "";
  position: absolute;
  top: 0;
  left: -30px;
  width: 0;
  height: 0;
  border-right: 30px solid transparent;
  border-bottom: 80px solid transparent;
  border-top: 80px solid transparent;
}

.selectedContainer::after {
  content: "";
  position: absolute;
  top: 0;
  right: -30px;
  width: 0;
  height: 0;
  border-left: 30px solid transparent;
  border-bottom: 80px solid transparent;
  border-top: 80px solid transparent;
}

@media only screen and (max-width: 768px) {
  .selectedContainer {
    width: 130px;
    height: 180px;
    border-radius: 2px;
  }

  .selectedContainer::before {
    left: -40px;
    border-right: 41px solid transparent;
    border-bottom: 90px solid transparent;
    border-top: 90px solid transparent;
  }

  .selectedContainer::after {
    right: -40px;
    border-left: 41px solid transparent;
    border-bottom: 90px solid transparent;
    border-top: 90px solid transparent;
  }
}

@media only screen and (max-width: 768px) {
  .selectedContainer {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(calc(-50% - 41px), -50%) scale(1.8) !important;
  }
}

@media only screen and (max-width: 610px) {
  .selectedContainer {
    transform: translate(calc(-50% - 41px), -50%) scale(3.0) !important;
  }
}

.selectedContainer.visible {
  visibility: visible;
  transform: scale(1.8);
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  height: 100%;
  position: absolute;
  width: 120%;
  left: -10%;
  right: 10%;
  z-index: 1;
  cursor: default;
}

@media only screen and (max-width: 610px) {
  .content {
    font-size: 20px;
  }
}

.selectedNominationContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 7px;
  height: 100%;
  position: absolute;
  width: 120%;
  left: -10%;
  right: 10%;
  z-index: 1;
  cursor: default;
  margin-top: 5px;
}

@media only screen and (max-width: 768px) {
  .selectedNominationContent {
    font-size: 8px;
  }
}

.award-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 8px;
  height: 100%;
  position: absolute;
  width: 140%;
  left: -20%;
  right: 20%;
  z-index: 1;
  cursor: default;
}

.award-content .title {
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -5px;
  font-size: 14px;
}

@media only screen and (max-width: 768px) {
  .award-content .title {
    margin-top: 0;
    font-size: 12px;
  }
}

@media only screen and (max-width: 610px) {
  .award-content .title {
    margin-top: -20px;
    font-size: 20px;
  }
}

.nominationVoteLink {
  display: flex;
  text-decoration: underline;
  padding-top: 5px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.nominationVoteLink a {
  color: #4a4025;
}

.no-nomination {
  display: flex;
  padding-top: 5px;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.no-nomination span {
  color: #4a4025;
}

.award-winner-logo {
  margin-top: 20px;
  width: 100%;
}

.award-winner-logo img{
  height: 40px;
  max-width: 100%;
}

@media only screen and (max-width: 768px) {
  .award-winner-logo {
    margin-top: 5px;
    width: 100%;
  }
}

@media only screen and (max-width: 610px) {
  .award-winner-logo {
    margin-top: 20px;
  }
}

.akm img{
  height: 80px;
}

.akm .award-content .title {
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  font-size: 14px;
}

@media only screen and (max-width: 768px) {
  .akm img {
    margin-top: 10px;
    height: 50px;
  }

  .akm .award-content .title {
    margin-top: 10px;
    font-size: 12px;
  }
}

@media only screen and (max-width: 610px) {
  .akm img {
    height: 80px;
  }

  .akm .award-content .title {
    margin-top: 10px;
    font-size: 20px;
  }
}

.close-button {
  position: absolute;
  font-size: 14px;
  right: 3px;
  top: 5px;
  z-index: 100;
}

@media only screen and (min-width: 765px) {
  .close-button {
    visibility: hidden;
  }
}