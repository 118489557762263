.container {
  margin: 0 auto;
  min-width: 920px;
}

@media only screen and (max-width: 768px) {
  .container {
    min-width: 790px;
  }
}

@media only screen and (max-width: 610px) {
  .container {
    min-width: 610px;
  }
}

.page1 {
  display: block;
  margin-bottom: 3rem;
}
